@font-face {
    font-family: 'clan_m';
    font-display: swap;
    src: url('../fonts/ClanWebPro-Medium-News_subset.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/ClanWebPro-Medium-News_subset.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ClanWebPro-Medium-News_subset.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ClanWebPro-Medium-News_subset.woff') format('woff'), /* Pretty Modern Browsers */
    //url('../fonts/ClanWebPro-Medium-News_subset.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ClanWebPro-Medium-News_subset.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'clan_n';
    font-display: swap;
    src: url('../fonts/ClanWebPro-News_subset.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/ClanWebPro-News_subset.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ClanWebPro-News_subset.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ClanWebPro-News_subset.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/ClanWebPro-News_subset.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ClanWebPro-News_subset.eot.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: icon;
    font-display: swap;
    src: url(../fonts/icon.woff2) format("woff2"),
    url(../fonts/icon.woff) format("woff"),
    url(../fonts/icon.ttf) format("truetype"),
    url(../fonts/icon.svg#svgFontName) format("svg");
    font-weight: 400
}
